<template>
  <v-container>
    <v-card tile>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        item-key="id"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.displayname`]="{ item }">
          {{ item.displayname }}
        </template>
        <template v-slot:[`item.fax`]="{ item }">
          {{ item.fax }}
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          {{ item.phone }}
        </template>

        <template v-slot:[`item.Call`]="{ item }">
          <v-btn
            color="primary"
            @click="copyNumber(item.phone)"
            outlined
            class="mr-2 mb-2"
          >
            Copy Phone Number
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { auth, messages } from '../sharedPlugin';
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: 'Name', value: 'displayname' },
        { text: 'Phone Number', value: 'phone' },
        { text: 'Fax Number', value: 'fax' },
        { text: '', value: 'Call', sortable: false, width: '40' }
      ],

      items: []
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },

  created() {
    this.load();
  },

  methods: {
    ...messages.mapMethods(['newPresetMessage']),
    copyNumber(number) {
      const elem = document.createElement('textarea');
      elem.value = number;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand('copy');
      document.body.removeChild(elem);

      this.$store.commit('showSnackbar', {
        message: 'Phone number copied successfully',
        color: 'green'
      });
    },
    formatPhoneNumber(phone) {
      var cleaned = ('' + phone).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    },
    load() {
      this.loading = true;
      axios
        .post(
          'https://apiv1.inceptionllc.com/api/v1/connect/locations/getAll',
          {
            id: this.user.clinicId
          }
        )
        .then((response) => {
          this.items = response.data.map((item) => ({
            displayname: item.displayname,
            phone: this.formatPhoneNumber(item.phone),
            fax: item.fax ? this.formatPhoneNumber(item.fax) : '-'
          }));
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
};
</script>
